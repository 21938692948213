import React from "react";
import Page from "../components/Page";
import { graphql } from "gatsby";
import BlogCard from "../components/Blog/BlogCard";
import { GatsbyImage } from "gatsby-plugin-image"

export default function About({ data }) {
  const { posts } = data.blog;

  return (
    <>
      <Page title={"Om oss - Fiskesajten"} description={"Fiskesajten grundades av en grupp fiskeentusiaster som ville skapa en plattform där vi kunde dela med oss av våra kunskaper och passion för fiske."}>

        <main class="relative isolate">
          <div class="absolute inset-x-0 top-4 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl" aria-hidden="true">
            <div class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25" style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)"></div>
          </div>

          <div class="px-6 pt-14 lg:px-8">
            <div class="mx-auto max-w-2xl pt-20 text-center">
              <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Om Fiskesajten</h1>
              <p class="mt-6 text-lg leading-8 text-gray-700">
                Fiskesajten är en passionerad och dedikerad online-resurs. Målet är att inspirera och engagera fler fiskeentusiaster.
              </p>
            </div>
          </div>

          <div class="mx-auto mt-20 max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div class="grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
                <div>
                  <p>
                  Jag som är grundaren för Fiskesajten heter Lucas. Jag fiskade mycket som barn, men när universitetet och vuxenlivet kallade tog jag tyvärr en paus från fisket, men passionen försvann aldrig helt. Idag har jag återvänt till fisket, och det ledde också till att jag startade Fiskesajten.
                  </p>
                  <p class="mt-8">
                    Jag fiskar främst i Göteborgsregionen, och då främst efter abborre och gädda. Men det blir också en hel del fiske efter havsöring på västkusten. När det kommer till fisketekniker, är det främst jiggfiske och olika finesstekinker som gäller!
                    </p>
                </div>
                <div>
                  <p>
                    Varför startade jag Fiskesajten? Enkelt sagt för jag brinner för fiske, och jag ville helt enkelt dela med mig av mitt intresse. Jag tycker också det är kul att skriva och ville därmed skapa en plats där jag kunde dela med mig av tankar och saker jag lär mig, och kanske också inspirera andra börja fiska?
                  </p>
                  <p class="mt-8">
                    Du ska däremot veta att jag inte är någon fiskeexpert, och jag har mycket kvar att lära. Men man lär sig något nytt hela tiden. Grundtanken med Fiskesajten är därmed främst att skapa en blogg fylld med inspiration och kunskap för fiskeentusiaster, något som jag hoppats funnits när jag började fiska.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
            <GatsbyImage image={data.homeTop.childImageSharp.gatsbyImageData} class="aspect-[9/4] w-full object-cover xl:rounded-3xl" alt="trolling" />
          </div>

          <section>
          <div className="mt-32 px-4 mx-auto max-w-screen-xl lg:px-6">
          <div className="bg-gray-50 p-8 rounded-lg shadow-md mx-auto my-10 border border-gray-200 grid grid-cols-1 md:grid-cols-2 gap-4">
            <GatsbyImage
              alt="Lucas Rosvall"
              image={data.me.childImageSharp.gatsbyImageData}
              className="max-w-xs sm:max-w-sm rounded-full mx-auto "
            />
            <div>
            <h2 className="text-2xl mt-6 mb-3 tracking-tight font-bold text-gray-900">
             Hej! Det är jag som är Lucas! 🎣
            </h2>
            <p className="text-gray-800 text-lg mb-4">
              Det är jag på bilden som är skaparen till Fiskesajten. Som barn spenderade jag timmar vid vattnet. Men när universitetet och vuxenlivet kallade tog jag tyvärr en paus från fisket.
            </p>
            <p className="text-gray-800 text-lg mb-4"> 
              Nu efter en lång paus från fisket har jag dock återvänt, och kanske mer engagerad än någonsin? Det är också därför jag startade <b>Fiskesajten</b> för att dela med mig av mitt stora intresse.
            </p>
            <p className="text-gray-800 text-lg mb-4">
              Jag vill sprida kunskap och dela med mig av det som jag lär mig. Jag hoppas därför att du kommer att hitta något som inspirerar dig på bloggen!
            </p>
            </div>
           
          </div>
          </div>
        </section>

          <div class="mx-auto mt-32 max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl lg:mx-0">
              <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Våra värderingar</h2>
              <p class="mt-6 text-lg leading-8 text-gray-700">Vi tror på värderingar som bidrar till framtidens fiske, samtidigt som vi själva kan njuta av vårt stora fiskeintresse. Våra fyra grundprinciper är:</p>
            </div>
            <dl class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
              <div class="relative pl-9">
                <dt class="inline font-semibold text-gray-900">
                  <svg class="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M4.606 12.97a.75.75 0 01-.134 1.051 2.494 2.494 0 00-.93 2.437 2.494 2.494 0 002.437-.93.75.75 0 111.186.918 3.995 3.995 0 01-4.482 1.332.75.75 0 01-.461-.461 3.994 3.994 0 011.332-4.482.75.75 0 011.052.134z" clip-rule="evenodd" />
                    <path fill-rule="evenodd" d="M5.752 12A13.07 13.07 0 008 14.248v4.002c0 .414.336.75.75.75a5 5 0 004.797-6.414 12.984 12.984 0 005.45-10.848.75.75 0 00-.735-.735 12.984 12.984 0 00-10.849 5.45A5 5 0 001 11.25c.001.414.337.75.751.75h4.002zM13 9a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                  </svg>
                  Ständig utveckling.
                </dt>
                <dd class="inline">Sträva efter att bli bättre fiskare och testa nya fisketekniker.</dd>
              </div>
              <div class="relative pl-9">
                <dt class="inline font-semibold text-gray-900">
                  <svg class="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M11 2a1 1 0 10-2 0v6.5a.5.5 0 01-1 0V3a1 1 0 10-2 0v5.5a.5.5 0 01-1 0V5a1 1 0 10-2 0v7a7 7 0 1014 0V8a1 1 0 10-2 0v3.5a.5.5 0 01-1 0V3a1 1 0 10-2 0v5.5a.5.5 0 01-1 0V2z" clip-rule="evenodd" />
                  </svg>
                  Hållbarhet.
                </dt>
                <dd class="inline">Främja för ansvarsfullt fiske, samt respekt för naturen.</dd>
              </div>
              <div class="relative pl-9">
                <dt class="inline font-semibold text-gray-900">
                  <svg class="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M10 9a3 3 0 100-6 3 3 0 000 6zM6 8a2 2 0 11-4 0 2 2 0 014 0zM1.49 15.326a.78.78 0 01-.358-.442 3 3 0 014.308-3.516 6.484 6.484 0 00-1.905 3.959c-.023.222-.014.442.025.654a4.97 4.97 0 01-2.07-.655zM16.44 15.98a4.97 4.97 0 002.07-.654.78.78 0 00.357-.442 3 3 0 00-4.308-3.517 6.484 6.484 0 011.907 3.96 2.32 2.32 0 01-.026.654zM18 8a2 2 0 11-4 0 2 2 0 014 0zM5.304 16.19a.844.844 0 01-.277-.71 5 5 0 019.947 0 .843.843 0 01-.277.71A6.975 6.975 0 0110 18a6.974 6.974 0 01-4.696-1.81z" />
                  </svg>
                  Gemenskap.
                </dt>
                <dd class="inline">Uppmuntra samarbete och att hjälpa andra inom fiskegemenskapen.</dd>
              </div>
              <div class="relative pl-9">
                <dt class="inline font-semibold text-gray-900">
                  <svg class="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M10 2a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5A.75.75 0 0110 2zM10 15a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5A.75.75 0 0110 15zM10 7a3 3 0 100 6 3 3 0 000-6zM15.657 5.404a.75.75 0 10-1.06-1.06l-1.061 1.06a.75.75 0 001.06 1.06l1.06-1.06zM6.464 14.596a.75.75 0 10-1.06-1.06l-1.06 1.06a.75.75 0 001.06 1.06l1.06-1.06zM18 10a.75.75 0 01-.75.75h-1.5a.75.75 0 010-1.5h1.5A.75.75 0 0118 10zM5 10a.75.75 0 01-.75.75h-1.5a.75.75 0 010-1.5h1.5A.75.75 0 015 10zM14.596 15.657a.75.75 0 001.06-1.06l-1.06-1.061a.75.75 0 10-1.06 1.06l1.06 1.06zM5.404 6.464a.75.75 0 001.06-1.06l-1.06-1.06a.75.75 0 10-1.061 1.06l1.06 1.06z" />
                  </svg>
                  Passion.
                </dt>
                <dd class="inline">Njuta av och fira vårt intresse inom sportfiske.</dd>
              </div>
            </dl>
          </div>

  


          <div class="relative isolate -z-10 mt-32 sm:mt-40">
            <div className="container px-8 md:px-12 pb-20 mx-auto max-w-screen-xl">
              <h2 className="mb-4 text-4xl tracking-tight font-bold text-gray-900 ">
                Senaste från fiskebloggen
              </h2>
              <div className="blog-grid">
                {posts &&
                  posts.map((post) => {
                    const href = "/" + post.frontmatter.category + post.fields.slug;

                    return (
                      <BlogCard
                        key={post.id}
                        title={post.frontmatter.title}
                        description={post.frontmatter.description}
                        link={href}
                        image={post.frontmatter?.coverImage}
                      />
                    );
                  })}
              </div>
              <div class="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl" aria-hidden="true">
                <div class="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25" style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)"></div>
              </div>
            </div>


          </div>
        </main>

      </Page>
    </>
  );
}


export const pageQuery = graphql`
  query {
    homeTop: file(relativePath: { eq: "trolling.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    backoring: file(relativePath: { eq: "backoring.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(/blog/)/" } }
      limit: 3
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          category
          description
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        fields {
          slug
        }
        id
        excerpt(pruneLength: 120)
      }
    }
    me: file(relativePath: { eq: "lucas-fiskar.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
